import csharpimg from './csharp.png'
import pythonimg from './python.png'
import javascriptimg from './javascript.png'
import reactimg from './react.png'
import unityimg from './unity.png'
import djangoimg from './django.png'

export const cardList = 
[
    {
        "id": "csharp",
        "name": "C#",
        "img": csharpimg, 
        "imgClassname": "csharp-img",
    },
    {
        "id": "python",
        "name": "Python",
        "img": pythonimg, 
        "imgClassname": "python-img"
    },
    {
        "id": "javascript",
        "name": "JavaScript",
        "img": javascriptimg, 
        "imgClassname": "javascript-img"
    },
    {
        "id": "react",
        "name": "React",
        "img": reactimg, 
        "imgClassname": "react-img"
    },
    {
        "id": "unity",
        "name": "Unity",
        "img": unityimg, 
        "imgClassname": "unity-img"
    },
    {
        "id": "django",
        "name": "Django",
        "img": djangoimg, 
        "imgClassname": "django-img"
    }
]