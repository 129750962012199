import arsonist1 from "./arsonist1.png";
import arsonist2 from "./arsonist2.png";
import arsonist3 from "./arsonist3.png";
import arsonistancientscepterposter from "./arsonistancientsceptreposter.png";
import githubImg from "../github-logo.png";
import thunderstore from "../thunderstore.png";

export const projectsList = 
[
    {
        "id": "arsonist-ror2",
        "title": "Arsonist - Risk of Rain 2 Mod",
        "related_urls": [
            {
                "title": "Thundestore (Download link)",
                "url": "https://thunderstore.io/package/PopcornFactory/Arsonist_Mod/",
                "img": thunderstore,
            }, 
            {
                "title": "Github (Source code)",
                "url": "https://github.com/Popcorn-Factory/Arsonist-RoR2",
                "img": githubImg,
            }],
        "description": "This is a Modification to the game Risk of Rain 2 (RoR2) that adds a playable character survivor.\n You play as a pyromaniac who has a variety of fire-oriented abilities. This character requires resource management and careful planning in order to make the most of his abilities. I have worked on the programming for all this character's abilities, creating various custom components that work together and implement new functionality not seen in the base game of RoR2.\n I have also pushed to create VFX in the style of the original game, to make it seamlessly fit into the game as if it were a character apart of the base game. So far this mod has received 3 major updates, and has a total of 1,279,409 downloads total since Apr 2025.",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod."
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0."
            },
        ],
        "videos": [
            {
                "title": "Arsonist v1.0 Release Trailer",
                "location": "youtube",
                "url": "https://www.youtube.com/embed/Aez62FNzMTg"
            },
            {
                "title": "Arsonist v2.0 Update Trailer",
                "location": "youtube",
                "url": "https://www.youtube.com/embed/y8EZUXso7Lc"
            }
        ],
        "body_images": [
            {   
                "title": "Arsonist v3.0 Update Poster",
                "attribution": "Poster Art by DragonRollZ",
                "img": arsonistancientscepterposter,
            }

        ]
        
    },
    {
        "id": "lee-hyperreal-ror2",
        "title": "Lee: Hyperreal - Risk of Rain 2 Mod",
        "description": "",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod. "
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0"
            },
        ],
    },
    {
        "id": "arsonist-ror4",
        "title": "Arsonist - Risk of Rain 4 Mod",
        "description": "",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod. "
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0"
            },
        ],
    },
    {
        "id": "arsonist-ror5",
        "title": "Arsonist - Risk of Rain 5 Mod",
        "description": "",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod. "
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0"
            },
        ],
    },
    {
        "id": "arsonist-ror6",
        "title": "Arsonist - Risk of Rain 6 Mod",
        "description": "",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod. "
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0"
            },
        ],
    },
    {
        "id": "arsonist-ror7",
        "title": "Arsonist - Risk of Rain 7 Mod",
        "description": "",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod. "
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0"
            },
        ],
    },
    {
        "id": "arsonist-ror8",
        "title": "Arsonist - Risk of Rain 8 Mod",
        "description": "",
        "imgs": [
            {
                "img": arsonist1,
                "caption": "Arsonist fighting an Elder Lemurian using the Ancient Scepter mod, firing the Flamethrower ability."
            }, 
            {
                "img": arsonist2,
                "caption": "Arsonist firing a powered up fireball ability using the Ancient Scepter mod. "
            }, 
            {
                "img": arsonist3,
                "caption": "Arsonist setting off one of the new abilities that was introduced in v3.0"
            },
        ],
    }
]